export const config = {
	ThunderstormModule: {
		appName: 'IR Support Portal'
	},
	XhrHttpModule: {
		origin: "",
		timeout: 40000
	},
	ActivationModule: {
		userOnlyEnabled: true
	},
	frontend: {
		origin: "https://ir-q-support.elliq.co",
	},
	Module_Example: {
		remoteUrl: "/v1/sample/endpoint-example"
	},
	EnvironmentModule: {
		envName: "prod",
		kasperoFeUrl: "https://elliq-env-map.firebaseapp.com",
		apiServerUrl: "https://elliq-api-server-prod.p.elliq.co:443/api/"
	},
	PackageManagerModule: {
		websiteUrl: "https://ir-q-package-manager-prod.firebaseapp.com",
		functionUrl: "https://us-central1-ir-q-package-manager-prod.cloudfunctions.net/api"
	},
	ContactsModule: {
		websiteUrl: "https://ir-q-messaging.firebaseapp.com",
		defaultAreaCode: "+1",
	},
	CareCenterModule: {
		websiteUrl: "https://ir-q-care-center.firebaseapp.com",
		commandsUrl: "https://ir-commands.web.app"
	},
	KasperModule: {
		origin: "https://us-central1-elliq-env-map.cloudfunctions.net/api"
	},
	UnitsModule: {
		dbName: "elliq-env-prod"
	},
	FirebaseModule: {
		local: {
			apiKey: "AIzaSyAT3GZvC_UE9GxAd3vhYkj0JIXtxDjiFp8",
			authDomain: "ir-q-support-prod.firebaseapp.com",
			databaseURL: "https://ir-q-support-prod.firebaseio.com",
			projectId: "ir-q-support-prod",
			storageBucket: "ir-q-support-prod.appspot.com",
			messagingSenderId: "361946341617",
			appId: "1:361946341617:web:c111a54245eba691ee4950"
		},
		pm: {
			apiKey: "AIzaSyBlFsKO-qH8g2o-1ChTf8mb7VQhQTZ3oZY",
			authDomain: "ir-q-package-manager-prod.firebaseapp.com",
			databaseURL: "https://ir-q-package-manager-prod.firebaseio.com",
			projectId: "ir-q-package-manager-prod",
			storageBucket: "ir-q-package-manager-prod.appspot.com",
			messagingSenderId: "55862688917",
			appId: "1:55862688917:web:3a72fca407b3adeab32f9c",
			measurementId: "G-GRPQX3DF7Q"
		},
		pushResult: {
			id: "elliq-env-map",
			apiKey: "AIzaSyDZHdnc1mUdQTA4FjKrvmlszHfBy0JK6hk",
			authDomain: "elliq-env-map.firebaseapp.com",
			databaseURL: "https://elliq-env-map.firebaseio.com",
			projectId: "elliq-env-map",
			storageBucket: "elliq-env-map.appspot.com",
			messagingSenderId: "1067544170250",
			appId: "1:1067544170250:web:2acdc4358c16ee9b4034f3"
		},
		ks: {
			apiKey: "AIzaSyDZHdnc1mUdQTA4FjKrvmlszHfBy0JK6hk",
			authDomain: "elliq-env-map.firebaseapp.com",
			databaseURL: "https://elliq-env-map.firebaseio.com",
			projectId: "elliq-env-map",
			storageBucket: "elliq-env-map.appspot.com",
			messagingSenderId: "1067544170250",
			appId: "1:1067544170250:web:2acdc4358c16ee9b4034f3"
		},
		sd: {
			apiKey: "AIzaSyA7YqQxjZO8M_ayGUwkY3YrJPH4gU9jg5w",
			authDomain: "ir-q-state-dashboard-staging.firebaseapp.com",
			databaseURL: "https://ir-q-state-dashboard-staging.firebaseio.com",
			projectId: "ir-q-state-dashboard-staging",
			storageBucket: "ir-q-state-dashboard-staging.appspot.com",
			messagingSenderId: "868100673306",
			appId: "1:868100673306:web:0a82b76ed1eed42bcde6a3"
		}
	},
	LocaleModule: {
		defaultLocale: "en",
		locales: [
			{
				locale: "en",
				label: "Language_English",
				icon: "languages/en",
				texts: require(`./res/localization/en`)
			},
			{
				locale: "nl",
				label: "Language_Dutch",
				icon: "languages/nl",
				texts: require(`./res/localization/nl`)
			}
		]
	},
	installBlockerConditions: [
		{
			currentVersionLowerThan: "3.4.6",
			packageVersionIsEqualOrBiggerThan: "3.4.6",
			mustInstallFirst: "3.4.6"
		}
	],
	BigQueryModule: {
		dataSet: "ir-datastore-prod"
	}
};
